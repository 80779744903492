<template>
    <div class="text-center">
        <b-button variant="primary" @click="$emit('confirm')">
            <font-awesome-icon icon="check"/>
            Confirmer
        </b-button>
        <b-button variant="secondary" @click="$emit('cancel')">
            <font-awesome-icon icon="times"/>
            Annuler
        </b-button>
    </div>
</template>

<script>
    export default {
        name: "ConfirmCancelButtons"
    }
</script>
